
.bookingDiv{
    display: inline-block;
    background-color: white;
}
.bookingText{
    text-align: center;
    margin-bottom: 5px;
}
.bookingPrice{
    text-align: center;
}

.header{
    background: #fff !important;
}
.icon-next{
    color: black !important;
}
.icon-previous{
    color: black !important;
}
.month-label{
    color: black !important;
}
.week{
    background-color: #fff !important;
    height: 50px !important;
}

&.names{
    background-color: #fff !important;
    height: 50px !important;
}

.day{
    background-color: #fff !important;
    color: black !important;
    height: 50px !important;
    font-family: dense !important;
}

.booked-day:before{
    background-color: #ddd !important;
}
.booked-night:after{
    background-color: #ddd !important;
}
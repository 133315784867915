

.navContactDiv{
    display: inline-block;
    width: 100%;
    background-color: var(--c1);
    color: white;
}
.navContactText{
    display: inline-block;
    margin: 8px;
    margin-left: 20px;
    font-family: calibri;
    font-weight: 100;
    color: white;
    text-decoration: none;
}
.navIcon{
    font-size: 15px;
}

.navDiv{
    width: 100%;
    position: sticky;
    top: 0px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    background-color: white;
    z-index: 101;
}

.navLeft{
    display: inline-block;
    width: 30%;
}

.navTitle{
    margin-left: 20px;
}

.navRight{
    display: inline-block;
    width: calc(70%);
    text-align: right;
}

.navLink{
    display: inline-block;
    font-family: dense;
    font-size: 23px;
    letter-spacing: 2px;
    padding: 20px 10px;
    cursor: pointer;
}

.navButton{
    padding: 5px 10px;
    background-color: var(--c1);
    outline: none;
    border: none;
    border-radius: 5px;
    margin-right: 20px;
    margin-left: 10px;

    color: white;
    font-family: dense;
    font-size: 23px;
    letter-spacing: 2px;

    cursor: pointer;
}

/*
###################################################################
MOBILE
###################################################################
*/

.mobileNavButton{
    color: black;
    border: none;
    background-color: white;
    font-size: x-large;
    width: 100%;
    text-align: left;
    padding: 11px;
    padding-left: 15px;
    border-radius: 0px;
}
.mobileNavLink{
    padding: 10px 15px;
    border-top: solid 1px #eee;
    font-family: dense;
    letter-spacing: 2px;
    font-size: 21px;
}
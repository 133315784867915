
.bookingsDiv{
    background-color: white;
    padding-bottom: 800px;
}

.linkEdit{
    color: var(--c1);
    cursor: pointer;
}
.linkDelete{
    background-color: darkred;
    color: white;
    padding: 2px 15px;
    margin-left: 10px;
    border-radius: 5px;
    cursor: pointer;
}


/*MODAL*/
.modalTitle{
    text-align: center;
    font-size: 18px;
}

.bookingModal{
    position: absolute;
    background-color: white;
    width: calc(60% - 50px);
    margin-left: 20%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    top: 50px;
    border-radius: 15px;
    padding: 25px;
}

.modalLabel{
    padding-top: 10px;
    padding-bottom: 5px;
}
.modalInput{
    width: calc(100% - 12px);
    padding: 5px 5px;
    outline: none;
    border: solid 1px #ddd;
    background-color: #f1f1f1;
}
.modalTextarea{
    resize: vertical;
}

.bookingModalButton{
    position: absolute;
    right: 15px;
    top: 10px;
    padding: 8px 20px;
    background-color: var(--c1);
    color: white;
    border: none;
    outline: none;
    border-radius: 10px;
    font-size: 16px;
    cursor: pointer;
}

.modalBtn{
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    margin-top: 15px;
}

.modalBtnOk{
    background-color: var(--c1);
    color: white;
    border: solid 1px var(--c1);
}
.modalBtnClose{
    background-color: white;
    color: var(--c1);
    border: solid 1px var(--c1);
    margin-right: 10px;
}

.deleteModal{
    position: absolute;
    background-color: white;
    width: calc(40% - 50px);
    margin-left: 30%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    top: 50px;
    border-radius: 15px;
    padding: 25px;
}
.modalBtnDelete{
    background-color: darkred;
    border: solid 1px darkred;
}
.modalStatus{
    color: darkred;
    text-align: center;
}



.loginDiv{
    background-color: white;
    height: 100vh;
    text-align: center;
}
.loginBtn{
    outline: none;
    background-color: var(--c1);
    color: white;
    border: none;
    padding: 6px 15px;
    border-radius: 5px;
    font-size: 17px;
}
.input{
    outline: none;
    border: solid 1px #ddd;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    margin: 10px;
}
.loginText{
    text-align: center;
    padding-top: 10px;
}
.statusLoginText{
    color: darkred;
}

.sliderImg{
    width: 100%;
    opacity: 0.5;
}

.sliderTitle{
    position: absolute;
    top: 235px;
    color: white;
    width: 100%;
    font-family: dense;
    font-size: 85px;
    letter-spacing: 2px;
    text-align: center;
}
.sliderText{
    position: absolute;
    top: 312px;
    color: white;
    width: 100%;
    font-family: calibri-regular;
    font-size: 28px;
    letter-spacing: 2px;
    text-align: center;
}
.sliderButton{
    position: absolute;
    top: 360px;
    
    color: white;
    font-family: dense;
    font-size: 35px;
    letter-spacing: 2px;
    text-align: center;

    border: solid 1px white;

    padding: 10px;

    background-color: rgb(0, 43, 80, 0.3);

    width: calc(28% - 10px);
    margin-left: 36%;
    margin-right: 36%;

    cursor: pointer;
}
.sliderButton:hover{
    background-color: rgb(0, 43, 80, 0.6);
}

.mobileSliderTitle{
    top: 110px;
    font-size: 40px;
}
.mobileSliderText{
    top: 145px;
    font-size: 16px;
}
.mobileSliderButton{
    top: 170px;
    font-size: 22px;
    width: calc(70% - 20px);
    margin-left: 15%;
    margin-right: 15%;
}
*{
  margin: 0px;

  --c1: rgb(0, 43, 80);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

@font-face {
  font-family: dense;
  src: url(./fonts/Dense-Regular.otf);
}
@font-face {
  font-family: calibri-regular;
  src: url(./fonts/Calibri-Regular.ttf);
}


.title{
  font-family: dense;
  font-size: 33px;
  letter-spacing: 2px;
  text-align: center;
}
.text{
  font-family: calibri-regular;
  font-size: 18px;

}




.aboutdiv{
    background-color: white;
    position: relative;
    margin-top: -100px;
}
.aboutTitle{
    padding: 10px;
}

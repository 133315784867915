.contactDiv{
    display: inline-block;
    background-color: white;
    text-align: center;
}
.contactText{
    text-align: center;
}
.contactLink{
    color: var(--c1);
    cursor: pointer;
}
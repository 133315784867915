
.houseDiv{
    display: inline-block;
    background-color: white;
}

.houseScrollButton{
    display: block;
    text-align: center;
    padding: 4px 10px;
    background-color: white;
    border: solid 1px var(--c1);
    color: var(--c1);
    margin-bottom: 10px;
}
.houseScrollButton:hover{
    background-color: var(--c1);
    color: white;

    cursor: pointer;
}

.houseMapsDiv{
    vertical-align: top;
    display: inline-block;
    width: calc(50% - 5px);
    padding-right: 5px;
}
.houseRightDiv{
    display: inline-block;
    width: calc(50% - 5px);
    padding-left: 5px;
}
.housePropDiv{
    display: inline-block;
    width: 100%;
}

.housePropColumn{
    display: inline-block;
    width: 33.33%;
    vertical-align: top;
}
.propIcon{
    position: absolute;
    font-size: 15px;
    margin-left: -35px;
    margin-top: 4px;
}
.propIconBig{
    width: 40px;
    font-size: 22px;
}
.housePropHeader{
    margin-top: 20px;
    font-size: 30px;
    font-family: dense;
    letter-spacing: 2px;
}
.housePropCheck{
    padding: 10px 0px;
    padding-left: 40px;
    padding-right: 30px;
}


.destinationDiv{
    display: inline-block;
    width: 100%;
}
.destinationHeader{
    font-size: 30px;
    font-family: dense;
    letter-spacing: 2px;
    margin-top: 10px;
}
.destinationicon{
    width: 22px;
    text-align: center;
}
table{
    width: 100%;
}
td{
    padding: 10px;
    border-bottom: solid 1px #ddd;
    width: calc(25%-20px);
}

.mobileProp{
    padding: 5px 0px;
    padding-left: 40px;
    padding-right: 30px;
}

.mobileHeaderProp{
    margin-top: 10px;
    font-size: 25px;
    font-family: dense;
    letter-spacing: 2px;
}

.propIconMobile{
    width: 40px;
    font-size: 20px;
}
.propTitleMobile{
    font-size: 30px;
    font-family: dense;
    letter-spacing: 2px;
    padding-top: 10px;
}
.mobileHouseDiv{
    width: 100%;
}
.mobileMapDiv{
    width: 100%;
    padding-bottom: 15px;
}